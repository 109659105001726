@keyframes rotateLogo {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderContainer {
  position: relative;
  transform: rotate(45deg);
  padding-left: 11px;
  margin-right: -11px;
}

.loaderDot {
  width: 11px;
  height: 11px;
  background: #000;
  border-radius: 50%;
  transition: all ease-in-out;
  margin-left: -100%;

  &.loaderSpinner {
    margin:0;
    transform-origin: -50% -50%;
    animation-name: rotateLogo;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}
