.selectableTextarea {
  white-space: pre-wrap;
  background: transparent;
  width: 275px;
  border: 0;
  resize: none;
  color: #484848;
  font-size: 14px;
  line-height: 22px;
  height: 22px;

  &:active {
    border: 0;
  }
}
