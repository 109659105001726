.subUsersList {
  table {
    padding-left:0;
    padding-right:0;

    thead {
      display: none;
    }

    td {
      padding-left:0;
      padding-right:0;
    }
  }
}
