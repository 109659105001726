// needed to ensure borders appear on tables
table {
  border-collapse: collapse;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  font-family: Clarkson,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

textarea, input, button, select {
  font-family: inherit; font-size: inherit;
}
